<template>
  <div
    class="document bg-nColorFillGray border border-nColorStrokeGray rounded-xl"
    v-if="pages"
  >
    <flipbook class="flipbook" :pages="pages" :zooms="null"></flipbook>
  </div>
</template>

<script>
import Flipbook from "flipbook-vue";
export default {
  name: "Document",
  components: { Flipbook },
  props: {
    document: {
      type: Object,
      required: true,
    },
  },
  methods: {},
  mounted() {
    this.pages = this.document.pages;
  },

  data() {
    return {
      pages: null,
    };
  },
};
</script>

<style scoped>
.flipbook {
  width: 90vw;
  height: 90vh;
}
</style>
