<template>
  <div
    class="px-3 md:px-0 container max-w-8xl md:pt-10 mx-auto relative"
    v-if="dataFetched"
  >
    <MobileHeader
      :headingText="getHeaderText()"
      routeName="OpenEventDocumentLibrary"
      class="block md:hidden"
    />
    <Heading
      :headingText="getHeaderText()"
      routeName="OpenEventDocumentLibrary"
      class="hidden md:block"
    />
    <div v-if="dataFetched" class="mt-20 md:mt-5">
      <div class="document-library bg-white rounded md:rounded-xl">
        <Document :document="document" v-if="document" />
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import ApiService from "@/common/api.service";
import { apiResource } from "@/common/app.config";
import Document from "@/components/Demo/DocumentLibrary/Document.vue";
import MobileHeader from "@/components/Demo/MobileHeader.vue";
import Heading from "@/components/Demo/Heading.vue";
import obkSlugs from "@/common/slugs";
import Footer from "@/components/Demo/CatalogElements/DesktopFooter.vue";

export default {
  name: "DocumentViewer",
  created() {
    ApiService.get(apiResource.demo.getDetails, {
      slug: obkSlugs.documentLibrary,
    })
      .then(({ data }) => {
        this.document = data.data.exp_details.documents.filter(
          (doc) => doc.document_id === this.$route.params.id
        )[0];
        this.dataFetched = true;
      })
      .catch(() => {});
  },
  components: {
    Document,
    MobileHeader,
    Heading,
    Footer,
  },
  computed: {},
  data() {
    return {
      document: null,
      dataFetched: false,
      obkSlugs,
    };
  },
  methods: {
    getHeaderText() {
      return this.document.title;
    },
  },
};
</script>

<style scoped></style>
